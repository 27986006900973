<template>
  <section>
    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <!--   <div>
              <b-card-title></b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div> -->
            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
          <!-- Card Body  -->
          <b-card-body>
            <div style="display: flow-root;">
              <b-button @click="exportDetalle()"
                style="float:right; margin: 0px 0px 15px 15px; border-color: #ff7f50 !important; background-color: #FF7F50 !important;">
                Descargar detalle
              </b-button>
            </div>
            <ag-grid-vue style="width: 100%; height: 500px" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="rowData" :pinnedTopRowData="pinnedTopRows" @grid-ready="onGridReady">

            </ag-grid-vue>
          </b-card-body>
        </b-card>
        <b-button @click="goDetalleOptimizacion()"
          style="height: 50px; background-color: #053743 !important; float:right; margin-right: 20px;  margin-top: 20px; padding-top: 17px;">
          Volver a Detalle Optimización</b-button>
      </b-col>
    </b-row>
  </section>
</template> 

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BCardTitle,
  BTableSimple
} from "bootstrap-vue";

import { AgGridVue } from "ag-grid-vue";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue';
import LayoutVerticalVue from "@/@core/layouts/layout-vertical/LayoutVertical.vue";
import LayoutContentRendererDefaultVue from "@/@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue";
import useApiServices from '@/services/useApiServices.js';
import axios from '@axios';
var qs = require('qs');

export default {

  components: {
    LayoutVerticalVue,
    LayoutContentRendererDefaultVue,
    BCardTitle,
    BCard,
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BLink,
    BTableSimple,
    AgGridVue
  },

  data() {

    return {
      columnDefs: [],
      rowData: [],
      pinnedTopRows: [],

      gridApi: null,
      columnApi: null,
      excelStyles: [],

    }
  },

  mounted() {
    // console.log(this.$route.query.optimization_id);
    let optimization_id = this.$route.query.optimization_id;
    let mezclas = this.$route.query.mezclas;
    // console.log(mezclas)
    // let query = qs.stringify({ optimization_id: optimization_id, mezclas: mezclas })
    // console.log(query);
    this.updateTablaMezcla(optimization_id);

    this.excelStyles = [
      {
        id: "cell",
        alignment: {
          horizontal: 'Left', vertical: 'Center'
        },
      }
    ];
  },
  methods: {
    updateTablaMezcla(optimization_id) {

      let query = qs.stringify({ optimization_id: optimization_id, mezclas: this.$route.query.mezclas })

      axios.request({
        method: "get",
        url: useApiServices.detalleOptimizacion.detalleNoUtilizados + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          let columnDefs = response.data.columnDefs;
          // console.log(response);
          this.columnDefs = columnDefs;
          this.rowData = response.data.rowData;
          this.pinnedTopRows = response.data.pinnedTopRows;

          this.rowSelection = 'multiple';

        })
    },
    goDetalleOptimizacion() {
      let optimization_id = this.$route.query.optimization_id;

      this.$router.push({
        name: 'molymet-detalle-optimizacion', query: {
          optimization_id: optimization_id
        }
      })
    },
    exportRecipe() {
      this.gridApi.exportDataAsExcel();

    },
    exportDetalle() {
      this.gridApi.exportDataAsExcel({
        fileName: 'existencias_no_utilizadas.xlsx',
      });

    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const createServerSideDatasource = () => {
        return {
          getRows: this.getRows,
        };
      };

      const updateData = () => {
        // create datasource with a reference to the fake server
        var datasource = createServerSideDatasource();
        // register the datasource with the grid
        params.api.setServerSideDatasource(datasource);
      };

      updateData();

      this.$emit('gridReady', this.gridApi, this.gridColumnApi, params);
    },
  },
};

</script>
